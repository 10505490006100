import { post } from '@/api'

export default {
  namespaced: true,
  state () {
    return {}
  },
  actions: {
    createPayOrder ({ rootState }, payload = {}) {
      const purchaseData = {
        event_id: rootState.purchase.event_id,
        ticket_id: rootState.purchase.ticket_id,
        ticket_plat: rootState.purchase.ticket_plat,
        pay_type: 1
      }
      return post('/event/CreatePayOrder', {
        ...purchaseData,
        ...payload
      })
    },
    createNoPayOrder ({ rootState }, payload = {}) {
      const purchaseData = {
        event_id: rootState.purchase.event_id,
        ticket_id: rootState.purchase.ticket_id,
        ticket_plat: rootState.purchase.ticket_plat,
      }
      return post('/event/CreateNoPayOrder', {
        ...purchaseData,
        ...payload
      })
    },
    completeOrder (_, payload) {
      return post('/event/PayOver', payload)
    }
  }
}
