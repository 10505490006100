import { createStore } from 'vuex'
import persistedstate from 'vuex-persistedstate'
import sys from './modules/sys'
import user from './modules/user'
import device from './modules/device'
import purchase from './modules/purchase'
import order from './modules/order'

// Persistence of user information data.
const persistedstatePlugin = persistedstate({
  key: 'tickitin',
  paths: ['user', 'purchase']
})

export default createStore({
  modules: {
    sys,
    user,
    device,
    purchase,
    order
  },
  plugins: [persistedstatePlugin]
})
