import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import useDevice from '@/composables/useDevice';

import { defineAsyncComponent } from 'vue';
export default {
  __name: 'index',
  setup(__props) {
    const {
      isWebDevice
    } = useDevice();
    const SystemDialogWeb = defineAsyncComponent(() => import('./SystemDialogWeb.vue'));
    const SystemDialogMini = defineAsyncComponent(() => import('./SystemDialogMini.vue'));
    return (_ctx, _cache) => {
      return _unref(isWebDevice) ? (_openBlock(), _createBlock(_unref(SystemDialogWeb), {
        key: 0
      })) : (_openBlock(), _createBlock(_unref(SystemDialogMini), {
        key: 1
      }));
    };
  }
};