import jquery from 'jquery'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import FBSignInButton from 'vue-facebook-signin-button'
import VueAppleLogin from 'vue-apple-login'
import { Locale } from 'vant'
// 引入 vant 英文语言包
import enUS from 'vant/es/locale/lang/en-US'

import '@/styles/style.css'
import '@/styles/_flex.scss'
import '@/styles/_font.scss'
import '@/styles/_padding.scss'
import '@/styles/_margin.scss'


window.jQuery = window.$ = jquery

const app = createApp(App)
app.use(FBSignInButton)
app.use(router)
app.use(store)

app.use(VueAppleLogin, {
  clientId: 'com.tickitin.tickets',
  scope: 'name email',
  redirectURI: 'https://www.tickitin.com',
  state: 'time.toString()',
  usePopup: true,
})
Locale.use('en-US', enUS)

app.mount('#app')
