import { createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import TheTopNav from '../components/theTopNav/index.vue';
export default {
  __name: 'TopNavView',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(TheTopNav, {
        class: "the-top-nav"
      }), _createVNode(_component_router_view)], 64);
    };
  }
};