import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import useDevice from '@/composables/useDevice';

import { defineAsyncComponent } from 'vue';
export default {
  __name: 'index',
  setup(__props) {
    const {
      isWebDevice
    } = useDevice();
    const LoadingWeb = defineAsyncComponent(() => import('./LoadingWeb.vue'));
    const LoadingMini = defineAsyncComponent(() => import('./LoadingMini.vue'));
    return (_ctx, _cache) => {
      return _unref(isWebDevice) ? (_openBlock(), _createBlock(_unref(LoadingWeb), {
        key: 0
      })) : (_openBlock(), _createBlock(_unref(LoadingMini), {
        key: 1
      }));
    };
  }
};