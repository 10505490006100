import { post } from '@/api'

export default {
  namespaced: true,
  state () {
    return {
      ticket_id: undefined,
      event_id: undefined,
      ticket_plat: '',
      title: '',
      summary: '',
      amount: 1,
    }
  },
  getters: {
    hasPurchase (state) {
      return state.event_id && state.ticket_id
    }
  },
  mutations: {
    setPurchaseData (state, purchaseData = {}) {
      state.ticket_id = purchaseData.ticket_id || undefined
      state.event_id = purchaseData.event_id || undefined
      state.ticket_plat = purchaseData.ticket_plat || ''
      state.title = purchaseData.title || ''
      state.summary = purchaseData.summary || ''
      state.amount = purchaseData.amount || 1
    }
  },
  actions: {
    createPurchaseVoucherPoint (_, payload) {
      return post('/event/PointDiscount', payload).then((res) => {
        if (res && res.data) {
          if (res && res.data) {
            return res.data
          } else {
            throw new Error('Interface data error in "/event/PointDiscount".')
          }
        }
      })
    },
    createPurchaseVoucher (_, payload) {
      return post('/event/EnterCode', payload).then((res) => {
        if (res && res.data) {
          if (res && res.data) {
            return res.data
          } else {
            throw new Error('Interface data error in "/event/EnterCode".')
          }
        }
      })
    },
    createPurchaseOrder ({ state }) {
      return post('/event/ConfirmOrder', {
        event_id: state.event_id,
        ticket_id: state.ticket_id,
        ticket_plat: state.ticket_plat,
        amount: state.amount
      }).then((res) => {
        if (res && res.data) {
          return res.data
        } else {
          throw new Error('Interface data error in "/event/ConfirmOrder".')
        }
      })
    }
  }
}
