export default [
  {
    // login
    path: '/login',
    name: 'login',
    component: () => import('../../views/sign/Login.vue')
  },
  // {
  //   // regist
  //   path: '/regist',
  //   name: 'regist',
  //   component: () => import('../../views/sign/Regist.vue')
  // },
  {
    path: '/seat-map-for-app',
    name: 'seat-map-for-app',
    component: () => import('@/views/seat-map-for-app.vue'),
  }
]