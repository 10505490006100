export default [
  {
    path: '/modify',
    redirect: { name: 'modifyInfo' },
    component: () => import('@/layouts/ModifyView.vue'),
    children: [
      {
        path: '/modify/info',
        name: 'modifyInfo',
        meta: {
          text: 'Basic information'
        },
        components: {
          side: () => import('@/views/modify/components/ModifySide.vue'),
          default: () => import('@/views/modify/ModifyInfo.vue')
        }
      },
      {
        path: '/modify/password',
        name: 'modifyPassword',
        meta: {
          text: 'Password'
        },
        components: {
          side: () => import('@/views/modify/components/ModifySide.vue'),
          default: () => import('@/views/modify/ModifyPassword.vue')
        }
      },
      {
        path: '/modify/bank',
        name: 'modifyBank',
        meta: {
          text: 'Bank account'
        },
        components: {
          side: () => import('@/views/modify/components/ModifySide.vue'),
          default: () => import('@/views/modify/ModifyBank.vue')
        }
      },
      {
        path: '/close/account',
        name: 'closeAccount',
        meta: {
          text: 'Close account'
        },
        components: {
          side: () => import('@/views/modify/components/ModifySide.vue'),
          default: () => import('@/views/modify/CloseAccount.vue')
        }
      },
    ]
  }
]