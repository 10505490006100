/** @format */

import axios from './request'
let get = function (url, params, headers) {
  return axios({
    method: 'get',
    url: url,
    params,
    headers,
  })
}
let post = function (url, params) {
  return axios({
    method: 'post',
    url: url,
    data: params,
  })
}
let postUpload = function (url, params) {
  return axios({
    method: 'post',
    url: url,
    data: params,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
export { get, post, postUpload }
