import axios from '@/api/request'
import { ACCOUNT_STATUS } from '../const'

export default {
  namespaced: true,
  state: {
    token: '', // 登录token
    
    /** userInfo */
    account_info: '', // 账号信息
    accounttype: undefined, // 账号类型
    avatar: '', // 用户头像url
    first_name: '', // 姓
    last_name: '', // 名
    username: '', // 用户名
    geoLocation: '', // 地理位置

    /** tabInfo */
    integral: 0, // 积分
    introduction: '', // 个人简介
    name: '', // 名称，后端返回的名称。区别于 getter.userName(= first_name + lasteName)
    ticket_count: 0, // 票券数量
  },
  getters: {
    isLogin (state) {
      return Boolean(state.token)
    },
    userName (state) {
      let name = ''
      if (state.first_name) {
        name += ' ' + state.first_name
      }
      if (state.last_name) {
        name += ' ' + state.last_name
      }
      return name.trim()
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setUserInfo(state, info = {}) {
      state.account_info = info.account_info || ''
      state.account_type = info.account_type || undefined
      state.avatar = info.avatar || ''
      state.first_name = info.first_name || ''
      state.last_name = info.last_name || ''
      state.username = info.username || ''
    },
    setUserTabInfo (state, tabInfo = {}) {
      state.integral = tabInfo.integral
      state.introduction = tabInfo.introduction
      state.name = tabInfo.name
      state.ticket_count = tabInfo.ticket_count
    },
    setUserGeoLocation (state, geoLocation = '') {
      state.geoLocation = geoLocation
    }
  },
  actions: {
    userLogin ({ commit, dispatch }, payload = {}) {
      const { token } = payload
      if (token) {
        commit('setToken', token)
        dispatch('user/getUserInfo')
      } else {
        console.error('user login error.')
      }
    },
    getUserInfo ({ commit }) {
      return axios.get('/my/MyInfo', { isNotLoading: true }).then(res => {
        if (res && res.data) {
          commit('setUserInfo', res.data)
        }
      })
    },
    updateUserInfo({ dispatch }, data) {
      return axios.post('/my/EditInfo', data).then((res) => {
        setTimeout(function () {
          dispatch('getUserInfo')
          dispatch('getUserTabInfo')
        }, 100)
      })
    },
    getUserTabInfo ({ commit }) {
      axios.get('/my/TabInfo', { isNotLoading: true }).then(res => {
        if (res && res.data) {
          commit('setUserTabInfo', res.data)
        }
      })
    },
    updateUserPassword (_, data) {
      return axios.post('/my/ChangePassword', data)
    },
    clearUserInfo ({ commit }) {
      commit('setToken', '')
      commit('setUserInfo')
      commit('setUserTabInfo')
      // commit('setUserGeoLocation')
      commit('purchase/setPurchaseData', {}, { root: true })
      commit('sys/setStatus', ACCOUNT_STATUS.NORMAL, { root: true })
    },
    getIntegralRule () {
      return axios.get('/my/PointRule')
    },
    getBankInfo () {
      return axios.get('/my/BankInfo').then(res => {
        if (res && res.data) {
          return res.data
        } else {
          throw new Error('get bank info error.')
        }
      })
    },
    updateBankInfo (_, data) {
      return axios.post('/my/EditBankInfo', data)
    },
    getCloseAccountInfo (_, data) {
      return axios.get('/my/DeleteInfo', data).then(res => {
        if (res && res.data) {
          return res.data
        } else {
          throw new Error('get close account info error.')
        }
      })
    },
    closeAccount ({ commit }, data) {
      return axios.post('/my/DeleteAccount', data).then((res) => {
        commit('clearUserInfo')
      })
    }
  },
}
