export default [
  {
    path: '/profile',
    redirect: { name: 'profile' },
    component: () => import('@/layouts/ProfileView.vue'),
    children: [
      {
        path: '',
        name: 'profile',
        meta: {
          title: 'profile'
        },
        components: {
          side: () => import('@/views/profile/components/ProfileSide.vue'),
          default: () => import('@/views/profile/ProfileTicket.vue')
        }
      },
      {
        path: '/profile/ticket/:id',
        name: 'profileTicketDetail',
        meta: {
          title: 'profile ticket detail'
        },
        components: {
          side: () => import('@/views/profile/components/ProfileSide.vue'),
          default: () => import('@/views/profile/ProfileTicketDetail.vue')
        }
      },
      {
        path: '/profile/report',
        name: 'profileReport',
        meta: {
          title: 'profile report'
        },
        components: {
          side: () => import('@/views/profile/components/ProfileSide.vue'),
          default: () => import('@/views/profile/ProfileReport.vue')
        }
      },
      {
        path: '/profile/integral',
        name: 'profileIntegral',
        meta: {
          title: 'profile integral'
        },
        components: {
          side: () => import('@/views/profile/components/ProfileSide.vue'),
          default: () => import('@/views/profile/ProfileIntegral.vue')
        }
      }
    ]
  }
]
