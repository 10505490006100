/** @format */

import axios from 'axios'

import { ElMessage } from 'element-plus'
import store from '@/store/index'
import router from '@/router/index'
import { ACCOUNT_STATUS } from '@/store/const'

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 60 * 1000,
  headers: {
    'content-type': 'application/json'
  },
})

instance.interceptors.request.use(
  (config) => {
    if (store.state.user.token) {
      config.headers.token = store.state.user.token
    }
    if (!config.isNotLoading) {
      store.commit('sys/setLoading', true)
    }
    // 在发送请求之前做些什么
    return config
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    store.commit('sys/setLoading', false)

    // console.log(response.data)
    if (response.data.code == 200) {
      return response.data
    } else {
      let status = response.data.code
      if (status == 401 || status == 492) {
        ElMessage.error('Login status has expired, please log in again')
        store.dispatch('user/clearUserInfo', '')
        router.push('/login')
      } else if (status == 419) {
        ElMessage.error('Frozen, please contact the administrator')
        store.dispatch('user/clearUserInfo', '')
        router.replace('/login')
      } else if (status == 490) {
        // The account has been banned and banned.
        store.commit('sys/setStatus', ACCOUNT_STATUS.DISABLE)
      } else {
        ElMessage.error(response.data.msg)
      }
      return Promise.reject(response.data)
    }
  },
  (error) => {
    store.commit('sys/setLoading', false)
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么

    // console.log(error.response.status)
    return Promise.reject(error)
  }
)

export default instance
