import { ACCOUNT_STATUS } from '../const'

export default {
  namespaced: true,
  state () {
    return {
      loading: false, // global loading status.
      status: ACCOUNT_STATUS.NORMAL, // account status: normal.
    }
  },
  mutations: {
    setLoading(state, status) {
      state.loading = status
    },
    setStatus (state, status) {
      if (Object.values(ACCOUNT_STATUS).includes(status)) {
        state.status = status
      } else {
        console.error('Account status setting error.')
      }
    }
  }
}