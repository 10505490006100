import { useStore } from 'vuex';
function useDevice() {
  const store = useStore();
  const isMiniDevice = computed(() => store.getters['device/isMiniDevice']);
  const isWebDevice = computed(() => store.getters['device/isWebDevice']);
  return {
    isMiniDevice,
    isWebDevice
  };
}
export default useDevice;