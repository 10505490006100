import { createRouter, createWebHashHistory } from 'vue-router'
import topNavRoutes from './modules/topNav'
import blankRoutes from './modules/blank'
import statusRoutes from './modules/status'
import profileRoutes from './modules/profile'
import modifyRoutes from './modules/modify'

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      redirect: { name: 'home' }
    },
    ...topNavRoutes,
    ...blankRoutes,
    ...statusRoutes,
    ...profileRoutes,
    ...modifyRoutes
  ],
})
router.afterEach((to, from) => {
  const { title } = to.meta || {}
  if (title) {
    document.title = to.meta.title
  }
})

export default router
